/** @jsxImportSource theme-ui */
import React from "react";
import Content from "../../components/Content";
import Breadcrumbs from "../../components/Breadcrumbs";
import { BaseStyles } from "theme-ui";

const urlToIdentifier = (url) => {
  let _url = url.trim();
  _url = _url.startsWith("/") ? _url.slice(1) : _url; // remove leading slash
  _url = _url.endsWith("/") ? _url.slice(0, -1) : _url; // remove trailing slash
  _url = _url.replace(/\//g, "-");
  return _url.trim();
};

const Renderer = ({
  content,
  excerpt,
  contentComponent,
  title,
  location,
  ...props
}) => {
  const ContentRenderer = contentComponent || Content;

  return (
    <div {...props}>
      <Breadcrumbs names={[title]} location={location} />
      <BaseStyles>
        <div
          sx={{
            maxWidth: "container",
          }}
        >
          <section className={urlToIdentifier(location.pathname)}>
            <h2>{title}</h2>

            <ContentRenderer content={content} />
          </section>
        </div>
      </BaseStyles>
    </div>
  );
};

export default Renderer;
