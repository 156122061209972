/** @jsxImportSource theme-ui */
import React from "react";
import Link from "./Link";

const recursiveGetPath = (pathArray, i) => {
  if (i > 0) {
    return recursiveGetPath(pathArray, i - 1) + "/" + pathArray[i];
  }
  return pathArray[i];
};

const Breadcrumbs = ({ names, location }) => {
  let urlPath = [];
  if (location && location.pathname) {
    const { pathname } = location;
    urlPath = pathname
      .split("/")
      .filter((x) => x.trim().length > 0 && x !== "/");
  }

  const crumbs = names.map((name, i) => {
    if (!location) {
      // Locations are not set in previews to prevent from navigating accidentally
      urlPath[i] = "#";
    }
    return { name, url: recursiveGetPath(urlPath, i) };
  });

  return (
    <ol sx={{ variant: "breadcrumbs.list" }}>
      <li sx={{ variant: "breadcrumbs.item" }}>
        <Link to="/">Start</Link>
      </li>
      {crumbs.map((crumb, i, arr) => {
        const isLastItem = arr.length - 1 === i;

        return (
          <li key={`breadcrumb-${i}`} sx={{ variant: "breadcrumbs.item" }}>
            {isLastItem ? (
              <span sx={{ variant: "srOnly" }}>{crumb.name}</span>
            ) : (
              <Link to={`/${crumb.url}`}>{crumb.name}</Link>
            )}
          </li>
        );
      })}
    </ol>
  );
};

export default Breadcrumbs;
