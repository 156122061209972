import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";
import { HTMLContent } from "../../components/Content";
import Renderer from "./Renderer";

const Template = ({ data, location }) => {
  const {
    html,
    excerpt,
    frontmatter: { title },
  } = data.markdownRemark;

  return (
    <Layout>
      <Seo title={title} pathname={location.pathname} description={excerpt} />
      <Renderer
        content={html}
        excerpt={excerpt}
        contentComponent={HTMLContent}
        title={title}
        location={location}
      />
    </Layout>
  );
};

export default Template;

export const query = graphql`
  query staticPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 100)
      frontmatter {
        title
      }
    }
  }
`;
